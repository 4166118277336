import { REMEMBER_COOKIE } from "config/constant";
// import { useAppSelector } from "hooks/reduxHooks";
import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";
interface Props {
  children: React.ReactNode;
}

const RequireAuth: React.FC<Props> = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const isRememberAvailable = Cookies.get(REMEMBER_COOKIE);
  // eslint-disable-next-line no-unused-vars
  const isSession = localStorage.getItem("sessionActive");
  // const user = useAppSelector((state) => state.auth.user);
  if (isRememberAvailable === "available" || isSession) {
    // if (user != null) {
    return <>{children}</>;
  } else {
    return <Navigate to="/auth/login" replace />;
  }
};

export default RequireAuth;
