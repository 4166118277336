/* eslint-disable max-lines */
/* eslint-disable max-len */
import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { withLoading } from "hocs/withLoading.hoc";
import RequireAuth from "./RequireAuth";
import MainLayout from "layouts/MainLayout/MainLayout";
import { ROUTES_PATH } from "./Routes.Constant";
import { LazyLoader } from "components/Common/LazyLoader/LazyLoader";
import {
  DashboardPage,
  FirstCalculator,
  SecondCalculator,
  CustomerDetails,
  CustomerList,
  CreateTicket,
  TicketList,
  FreshDeskDetails,
  InvoiceDetails,
  InvoiceList,
  OrderDetails,
  OrderList,
  PaymentList,
  RMAList,
  Hierarchy,
  ServiceList,
  ServicePart,
  Shipment,
  Warranty,
  NotFound,
  PackingSlipDetails,
  SalesRepList,
  UserRegistration,
  PerlickNews,
  ThirdCalculator,
  FourthCalculator,
} from "./routes";
import { PERLICK_ROUTES_PATH } from "./PerlickRoutes.Constant";
import NotAuthorizedPage from "pages/NotAuthorisedPage";
import { initGA, logPageView } from "utils/ga4";
import { RedirectApiErrorMessage } from "components/Common/PerlickCommon/RedirectApiErrorMessage/RedirectApiErrorMessage";

//Pages lazily loaded
const LoginPage = React.lazy(() => import("pages/LoginPage"));
const ResetPassword = React.lazy(() => import("components/ResetPassword/ResetPassword"));
const ForgotPassword = React.lazy(() => import("components/ForgotPassword/ForgotPassword"));
const ManageOrders = React.lazy(() => import("components/Orders/ManageOrder"));
const CreateOrder = React.lazy(() => import("components/CreateOrder/CreateOrder"));
const EditOrderPage = React.lazy(() => import("pages/EditOrderPage"));
const AddProducts = React.lazy(() => import("components/AddProducts/AddProducts"));
const EditProduct = React.lazy(() => import("components/EditProduct/EditProduct"));
const UserProfile = React.lazy(
  () => import("components/PerlickComponents/UserProfile/PerlickUserProfile")
);
const ChangePassword = React.lazy(() => import("components/UserProfile/ChangePassword"));
const AuthLayoutFallback = React.lazy(() => import("layouts/AuthLayout/AuthLayout"));
const Logout = React.lazy(() => import("./Logout"));

const Dashboard = withLoading(DashboardPage);
const LogoutFallback = withLoading(Logout);
const PerlickPackingSlip = withLoading(PackingSlipDetails);
export const DASHBOARD_PATH = "/";

interface IRoute {
  path: string;
  component: JSX.Element;
}

const routes: IRoute[] = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: PERLICK_ROUTES_PATH.DASHBOARD,
    component: <Dashboard />,
  },
  {
    path: PERLICK_ROUTES_PATH.CALCULATOR_1,
    component: <FirstCalculator />,
  },
  {
    path: PERLICK_ROUTES_PATH.CALCULATOR_2,
    component: <SecondCalculator />,
  },
  {
    path: PERLICK_ROUTES_PATH.CUSTOMER_DETAILS,
    component: <CustomerDetails />,
  },
  {
    path: PERLICK_ROUTES_PATH.CUSTOMER,
    component: <CustomerList />,
  },
  {
    path: PERLICK_ROUTES_PATH.CREATE_TICKET,
    component: <CreateTicket />,
  },
  {
    path: PERLICK_ROUTES_PATH.TICKET,
    component: <TicketList />,
  },
  {
    path: PERLICK_ROUTES_PATH.INVOICE_DETAIL,
    component: <InvoiceDetails />,
  },
  {
    path: PERLICK_ROUTES_PATH.FRESHDESK_DETAILS,
    component: <FreshDeskDetails />,
  },
  {
    path: PERLICK_ROUTES_PATH.INVOICE,
    component: <InvoiceList />,
  },
  {
    path: PERLICK_ROUTES_PATH.ORDER_DETAILS,
    component: <OrderDetails />,
  },
  {
    path: PERLICK_ROUTES_PATH.ORDERS,
    component: <OrderList />,
  },
  {
    path: PERLICK_ROUTES_PATH.PAYMENTS,
    component: <PaymentList />,
  },
  {
    path: PERLICK_ROUTES_PATH.RMA,
    component: <RMAList />,
  },
  {
    path: PERLICK_ROUTES_PATH.HIERARCHY,
    component: <Hierarchy />,
  },
  {
    path: PERLICK_ROUTES_PATH.SERVICE,
    component: <ServiceList />,
  },
  {
    path: PERLICK_ROUTES_PATH.SERVICE_PART,
    component: <ServicePart />,
  },
  {
    path: PERLICK_ROUTES_PATH.SHIPMENT,
    component: <Shipment />,
  },
  {
    path: PERLICK_ROUTES_PATH.WARRANTY,
    component: <Warranty />,
  },
  {
    path: PERLICK_ROUTES_PATH.CHANGE_PASSWORD,
    component: <ChangePassword />,
  },
  {
    path: PERLICK_ROUTES_PATH.CHANGE_PROFILE,
    component: <UserProfile />,
  },
  {
    path: "*",
    component: <NotFound />,
  },
  {
    path: PERLICK_ROUTES_PATH.PACKING_SLIP,
    component: <PerlickPackingSlip />,
  },
  {
    path: PERLICK_ROUTES_PATH.SALES_REP_LIST,
    component: <SalesRepList />,
  },
  {
    path: PERLICK_ROUTES_PATH.Perlick_News,
    component: <PerlickNews />,
  },
  {
    path: PERLICK_ROUTES_PATH.CALCULATOR_3,
    component: <ThirdCalculator />,
  },
  {
    path: PERLICK_ROUTES_PATH.CALCULATOR_4,
    component: <FourthCalculator />,
  },
  {
    path: "/user-not-found",
    component: <RedirectApiErrorMessage />,
  },
];

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  const suspenseWithLoader = (children: React.ReactNode, delay?: number) => {
    return (
      <Suspense fallback={<LazyLoader {...(delay ? { delay: delay } : {})} />}>{children}</Suspense>
    );
  };

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  return (
    <BrowserRouter>
      <RouteChangeTracker />
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          {routes.map((item: IRoute, id: number) => {
            return (
              <Route key={id} path={item.path} element={suspenseWithLoader(item.component, 800)} />
            );
          })}
          <Route index element={<Dashboard />} />
          <Route path={`${ROUTES_PATH.DASHBOARD}`} element={<Dashboard />} />
          <Route
            path={`${ROUTES_PATH.ORDERS}`}
            element={suspenseWithLoader(<ManageOrders />, 800)}
          />
          <Route
            path={`${ROUTES_PATH.CREATE_ORDER}`}
            element={suspenseWithLoader(<CreateOrder />)}
          />
          <Route
            path={`${ROUTES_PATH.EDIT_ORDER}/:orderId`}
            element={suspenseWithLoader(<EditOrderPage />)}
          />
          <Route
            path={`${ROUTES_PATH.ADD_PRODUCTS}/:orderId`}
            element={suspenseWithLoader(<AddProducts />)}
          />
          <Route
            path={`${ROUTES_PATH.EDIT_PRODUCT}/:orderProductId/:childSKU`}
            element={suspenseWithLoader(<EditProduct />)}
          />
          <Route
            path={`${ROUTES_PATH.USER_PROFILE}`}
            element={suspenseWithLoader(<UserProfile />)}
          />
          <Route
            path={`${ROUTES_PATH.CHANGE_PASSWORD}`}
            element={suspenseWithLoader(<ChangePassword />)}
          />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path={`${ROUTES_PATH.LOGIN}`} element={suspenseWithLoader(<LoginPage />)} />
        </Route>
        <Route path={`${ROUTES_PATH.LOGOUT}`} element={<LogoutFallback />} />
        <Route
          path={`${PERLICK_ROUTES_PATH.USER_REGISTRATION}`}
          element={suspenseWithLoader(<UserRegistration />)}
        />
        <Route
          path={`${ROUTES_PATH.FORGOT_PASSWORD}`}
          element={suspenseWithLoader(<ForgotPassword />)}
        />
        <Route
          path={`${ROUTES_PATH.RESET_PASSWORD}`}
          element={suspenseWithLoader(<ResetPassword />)}
        />
        <Route path={`${ROUTES_PATH.NOT_AUTHORISED}`} element={<NotAuthorizedPage />} />
      </Routes>
    </BrowserRouter>
  );
};

const RouteChangeTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};
