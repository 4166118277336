import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { perlickConstant } from "config/Perlick.Constant";
import { IPaymentListResponse } from "interfaces/ClientInterface/PerlickClientInterface/IPayment";

interface IPayment {
  paymentActivePageNumber: number;
  paymentTotalPage: number;
  paymentResponseList: IPaymentListResponse[];
  paymentListTime: string;
}

const initialState: IPayment = {
  paymentActivePageNumber: perlickConstant.DefaultPageIndex,
  paymentTotalPage: 0,
  paymentResponseList: [],
  paymentListTime: "",
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    getPaymentResponseList: (state, action: PayloadAction<IPaymentListResponse[]>) => {
      state.paymentResponseList = action.payload;
    },
    getPaymentRefreshTime: (state, action: PayloadAction<string>) => {
      state.paymentListTime = action.payload;
    },
    getPaymentTotalPage: (state, action: PayloadAction<number>) => {
      state.paymentTotalPage = action.payload;
    },
    getPaymentPageNumber: (state, action: PayloadAction<number>) => {
      state.paymentActivePageNumber = action.payload;
    },
  },
});

export const {
  getPaymentResponseList,
  getPaymentRefreshTime,
  getPaymentPageNumber,
  getPaymentTotalPage,
} = paymentSlice.actions;
export default paymentSlice.reducer;
