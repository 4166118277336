import { PerlickAnalyticsId } from "api/services/PerlickAPICalls/PerlickAnalytics.api";
import { perlickConstant } from "config/Perlick.Constant";
import ReactGA from "react-ga4";

let analyticsData: string | null = "";

let getAnalyticUID = "";

export const getAnalyticCall = (value: string) => {
  getAnalyticUID = value;
};

if (!analyticsData && !getAnalyticUID) {
  PerlickAnalyticsId(perlickConstant.PORTAL_ID).then((res) => {
    analyticsData = res.data.TagManagerModel?.AnalyticsUId;
  });
}

export const initGA = () => {
  if (analyticsData || getAnalyticUID) {
    ReactGA.initialize(analyticsData ?? getAnalyticUID);
  }
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
};
