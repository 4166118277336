import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// eslint-disable-next-line max-len
import {
  IFreshDeskState,
  IPerlickFreskDeskAllTicketResponse,
} from "./../../interfaces/ClientInterface/PerlickClientInterface/IFreshDesk";
import { perlickConstant } from "config/Perlick.Constant";

const initialState: IFreshDeskState = {
  id: 0,
  freshDeskList: [],
  freshdeskTotalPage: 0,
  freshDeskActivePageNumber: perlickConstant.DefaultPageIndex,
};

export const freshdeskSlice = createSlice({
  name: "freshdesk",
  initialState,
  reducers: {
    getFreshDeskTicketId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    getFreshDeskList: (state, action: PayloadAction<IPerlickFreskDeskAllTicketResponse[]>) => {
      state.freshDeskList = action.payload;
    },
    getFreshDeskTotalPage: (state, action: PayloadAction<number>) => {
      state.freshdeskTotalPage = action.payload;
    },
    getFreshDeskPageNumber: (state, action: PayloadAction<number>) => {
      state.freshDeskActivePageNumber = action.payload;
    },
  },
});

export const {
  getFreshDeskTicketId,
  getFreshDeskList,
  getFreshDeskTotalPage,
  getFreshDeskPageNumber,
} = freshdeskSlice.actions;
export default freshdeskSlice.reducer;
