import React from "react";
import { ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "store/store";
import { themeSetting } from "config/defaultSettings";
import { setupInterceptors } from "./api/http.api";
import { AbilityContext } from "ability/Can";
import defineAbilityFor from "ability/defineAbility";
setupInterceptors();
import { IntlProvider } from "react-intl";
import { messages } from "i18n";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <AbilityContext.Provider value={defineAbilityFor()}>
    <IntlProvider locale={"en"} messages={messages["en"]}>
      <Provider store={store}>
        <ConfigProvider theme={{ ...themeSetting }}>
          <App />
        </ConfigProvider>
      </Provider>
    </IntlProvider>
  </AbilityContext.Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals();
