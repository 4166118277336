/* eslint-disable indent */
import { DOLLAR } from "config/constant";
import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getGlobalSetting } from "api/services/localStorage.service";
import { DateFormatMapper, TimeFormatMapper, TimezoneMapper } from "config/timeZone";
import { perlickConstant } from "config/Perlick.Constant";
import { PERLICK_ROUTES_PATH } from "routes/PerlickRoutes.Constant";
// eslint-disable-next-line max-len
import { IColorCodeStatus } from "interfaces/ClientInterface/PerlickClientInterface/IOrder";
import Cookies from "js-cookie";
import { REMEMBER_COOKIE } from "config/constant";
const globalsettings = getGlobalSetting() ? JSON.parse(getGlobalSetting() || "") : {};

dayjs.extend(utc);
dayjs.extend(timezone);

export const camelize = (string: string): string => {
  return string
    .split(" ")
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)
    )
    .join("");
};

export const getDate = (date: string): string => {
  if (!date) return "";
  return date.split("T")[0];
};

export const getCurrencyPrice = (price: number, currency: string): string => {
  switch (currency) {
    case "USD": {
      return `$${price}`;
    }

    default: {
      return `$${price}`;
    }
  }
};

export const parseJwt = (token: string) => {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const trimStr = (str: string, charCount = 15) => {
  if (!str) return "";
  const res = str.substring(0, charCount);
  return res + "...";
};

export const isMorePages = (dataLength: number, perPageRecord = 10) => {
  dataLength = dataLength ? dataLength : 0;
  return dataLength > perPageRecord ? true : false;
};

export const getFooterText = () => {
  const today = new Date();
  const year = today.getFullYear();

  return ` © ${year} Perlick Corporation, All Rights Reserved.`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObjectEmpty = (obj: any = {}) => {
  return Object.keys(obj).length === 0;
};

// Generate the date and time string
export const dateString = () => {
  const currentDate = new Date();
  /* eslint-disable-next-line newline-per-chained-call */
  return currentDate?.toISOString()?.replace(/[-:]/g, "")?.slice(0, 14);
};

/**
 * This function is used to get the price prefixed with the passed currency symbol
 */
export const getPriceWithCurrency = (currency: string, price: number | string | null) => {
  if (!currency) {
    currency = DOLLAR;
  }

  return price != null ? `${currency}${price}` : "";
};

export const formatSelector = (data: string) => {
  return data?.trim()?.toLowerCase();
};

export const utcToLocal = (date: string) => {
  const gmtDateTime = moment.utc(date);
  const localDateTime = gmtDateTime.local().format("MM/DD/YYYY HH:MM A");
  return localDateTime;
};

export function deepCloneArray<T>(arr: T[]): T[] {
  return arr.map((item) => {
    if (Array.isArray(item)) {
      return deepCloneArray(item); // Recursively clone nested arrays
    } else if (typeof item === "object" && item !== null) {
      return { ...item }; // Clone objects using the spread operator
    }
    return item; // Return primitive values as is
  }) as T[];
}

export const checkPermissionToShow = (permission: boolean, section: React.ReactNode) => {
  return permission ? section : null;
};

export const statusColor = (status: string) => {
  const defaultColor = {
    OrderStateName: status,
    BackColor: "",
    TextColor: "",
  };

  if (localStorage.getItem("ItemStatus")) {
    const orderStatusData = JSON.parse(localStorage.getItem("ItemStatus") || "");
    if (orderStatusData != "") {
      let statusColor;
      typeof orderStatusData === "string"
        ? (statusColor = orderStatusData
            ? JSON.parse(JSON.parse(JSON.stringify(orderStatusData)))
            : [])
        : (statusColor = orderStatusData ? JSON.parse(JSON.stringify(orderStatusData)) : []);

      const colorCodes =
        statusColor.length > 0
          ? statusColor?.filter((items: IColorCodeStatus) => {
              if (items.OrderStateName.toLowerCase() == status.toLowerCase()) {
                return items;
              }
            })[0]
          : defaultColor;
      return colorCodes;
    }
  } else {
    return defaultColor;
  }
};

/*
 This method returns Date and time according to timezone which is set in global setting for display.
*/
export const convertDateTime = (date: string) => {
  const dateFormat = globalsettings?.DateFormat;
  const timeFormat = globalsettings?.TimeFormat;
  const displayTimeZone = globalsettings?.DisplayTimeZone;

  const convertedDate = dayjs
    .utc(date)
    .tz(TimezoneMapper[displayTimeZone])
    .format(DateFormatMapper[dateFormat] + " " + TimeFormatMapper[timeFormat]);
  return convertedDate;
};

/**
 * This method returns date according to the timezone which is set in global setting for display.
 *
 * @param date
 * @returns
 */
export const convertDate = (date: string) => {
  const dateFormat = globalsettings?.DateFormat;
  const targetTimeZone = globalsettings?.DisplayTimeZone;

  const convertedDate = dayjs
    .utc(date)
    .tz(TimezoneMapper[targetTimeZone])
    .format(DateFormatMapper[dateFormat]);
  return convertedDate;
};

/**
 * This method returns time according to the timezone which is set in global setting for display.
 * @param date
 * @returns
 */
export const convertTime = (date: string) => {
  const timeFormat = globalsettings?.TimeFormat;
  const targetTimeZone = globalsettings?.DisplayTimeZone;

  const convertedDate = dayjs
    .utc(date)
    .tz(TimezoneMapper[targetTimeZone])
    .format(TimeFormatMapper[timeFormat]);
  return convertedDate;
};

/** Date Format MM/DD/YYYY */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertDateFormat = (inputDate: string| any) => {
  let formattedDate;
  if (inputDate != null) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();
    const formattedDay = String(day).padStart(2, "0");

    formattedDate = `${month > 9 ? month : "0" + month}/${formattedDay}/${year}`;
  } else {
    formattedDate = "";
  }
  return formattedDate;
};

/** Status Value */
export const getStatusValue = (status: string) => {
  if (status.toLowerCase() === perlickConstant.RECEIVED_SHIPPED_IN_FULL.toLowerCase()) {
    return 5;
  } else if (status.toLowerCase() === perlickConstant.RECEIVED_PROCESSING_ORDER.toLowerCase()) {
    return 2;
  } else if (status.toLowerCase() === perlickConstant.RECEIVED_BUILD_IN_PROGRESS.toLowerCase()) {
    return 3;
  } else if (status.toLowerCase() === perlickConstant.RECEIVED_PARTIALLY_SHIPPED.toLowerCase()) {
    return 4;
  } else {
    return 1;
  }
};

/** Navigation and Menu show active */
export const getActiveMenuValue = (activeKey: string) => {
  if (activeKey === PERLICK_ROUTES_PATH.ORDER_DETAILS.slice(1)) {
    return PERLICK_ROUTES_PATH.ORDERS.slice(1);
  } else if (activeKey === PERLICK_ROUTES_PATH.INVOICE_DETAIL.slice(1)) {
    return PERLICK_ROUTES_PATH.INVOICE.slice(1);
  } else if (activeKey === PERLICK_ROUTES_PATH.CUSTOMER_DETAILS.slice(1)) {
    return PERLICK_ROUTES_PATH.CUSTOMER.slice(1);
  } else if (activeKey === PERLICK_ROUTES_PATH.PACKING_SLIP.slice(1)) {
    return PERLICK_ROUTES_PATH.SHIPMENT.slice(1);
  } else if (activeKey === PERLICK_ROUTES_PATH.CREATE_TICKET.slice(1)) {
    return PERLICK_ROUTES_PATH.TICKET.slice(1);
  } else if (activeKey === PERLICK_ROUTES_PATH.FRESHDESK_DETAILS.slice(1)) {
    return PERLICK_ROUTES_PATH.TICKET.slice(1);
  } else {
    return activeKey || "Dashboard";
  }
};

/** Currency add commas */
export const getSortedCurrency = (value: number | string) => {
  return value?.toLocaleString("en-US");
};

export const checkUserSession = () => {
  const isSession = localStorage.getItem("sessionActive");
  const isRememberMeCookie = Cookies.get(REMEMBER_COOKIE);
  if (isSession || isRememberMeCookie) {
    // if (user != null) {
    return true;
  } else {
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const firstLetterUpperCase = (str: any) => {
  return (
    str
      .toLowerCase()
      .split(" ")
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((word: any) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
      .join(" ")
  );
};

/** Convert Bytes to specified units */
const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
export const bytesConvertor = (x: string) => {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1000 && ++l) {
    n = n / 1000;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const checkErrorMessage = (statusCode: number) => {
  if (statusCode === 400 || statusCode === 500 || statusCode === 404) {
    return true;
  }
  return false;
};
