import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { perlickConstant } from "config/Perlick.Constant";
import { ISalesRepCustResponse } from "interfaces/ClientInterface/PerlickClientInterface/ISalesRep";

export interface ICustomerDetails {
  CustomerId: string;
  CustomerList: ISalesRepCustResponse[];
  CustomerTotalPage: number;
  CustomerActivePageNumber: number;
  CustomerListTime: string;
}

const initialState: ICustomerDetails = {
  CustomerId: "",
  CustomerList: [],
  CustomerTotalPage: 0,
  CustomerActivePageNumber: perlickConstant.DefaultPageIndex,
  CustomerListTime: "",
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    getCustomerList: (state, action: PayloadAction<ISalesRepCustResponse[]>) => {
      state.CustomerList = action.payload;
    },
    getCustomerRefreshTime: (state, action: PayloadAction<string>) => {
      state.CustomerListTime = action.payload;
    },
    getCustomerTotalPage: (state, action: PayloadAction<number>) => {
      state.CustomerTotalPage = action.payload;
    },
    getCustomerPageNumber: (state, action: PayloadAction<number>) => {
      state.CustomerActivePageNumber = action.payload;
    },
  },
});

export const {
  getCustomerList,
  getCustomerRefreshTime,
  getCustomerTotalPage,
  getCustomerPageNumber,
} = customerSlice.actions;

export default customerSlice.reducer;
