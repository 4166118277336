/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ResetPasswordRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
  RefreshClient,
  setClientToken,
  login,
} from "api/auth.api";

import {
  clearLocalStorage,
  deleteToken,
  deleteUser,
  persistUserId,
  persistUserDetails,
  readToken,
  removeAllCookies,
  setAxiosHeader,
} from "api/services/localStorage.service";
import { perlickConstant } from "config/Perlick.Constant";
import { COOKIES_EXPIRES, LOGIN_COOKIE, REMEMBER_COOKIE } from "config/constant";
import dayjs from "dayjs";
import { ILogin } from "interfaces/ClientInterface/ILogin";
import Cookies from "js-cookie";

export interface AuthSlice {
  token: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any | null;
  loading: boolean;
  error: string | null;
  salesRepId: string;
  email: string;
  ssoLoader: boolean;
}

const LOGIN_ERROR_MESSAGE = "Invalid Username or Password.";
const SalesId = localStorage.getItem("ExternalId");

const initialState: AuthSlice = {
  token: readToken(),
  user: null,
  loading: false,
  error: "",
  salesRepId: SalesId ? SalesId : "",
  email: "",
  ssoLoader: false,
};

const createAuthPayload = (data: ILogin) => {
  return {
    user: {
      username: data.username,
      password: data.password,
      remember: data.remember,
    },
    iswebstoreuser: data.iswebstoreuser,
    isssologin: data.isssologin,
  };
};

const startOfDay = dayjs().startOf("day");
const endOfDay = dayjs().endOf("day");

const minutesInADay = endOfDay.diff(startOfDay, "minute");

export const doLogin = createAsyncThunk(
  "auth/login",
  // eslint-disable-next-line no-unused-vars
  async (loginPayload: ILogin, { dispatch }) => {
    try {
      loginPayload.iswebstoreuser = true;
      const response = await login(createAuthPayload(loginPayload));
      const storeGlobalAttribute = response.User.UserGlobalAttributes.filter(
        (x: { EntityName: string }) => {
          return x.EntityName === "store";
        }
      );
      const storeGlobalAttributeString = JSON.stringify(storeGlobalAttribute);

      localStorage.setItem("storeGlobalAttribute", storeGlobalAttributeString);

      if (loginPayload.remember) {
        Cookies.set(REMEMBER_COOKIE, "available", {
          expires: COOKIES_EXPIRES * minutesInADay,
        });
        Cookies.set(LOGIN_COOKIE, loginPayload.username, {
          expires: COOKIES_EXPIRES * minutesInADay,
        });
      } else {
        Cookies.remove(REMEMBER_COOKIE);
        localStorage.setItem("sessionActive", "available");
      }

      if (loginPayload.isssologin) {
        const globalAttributeVal = JSON.parse(
          JSON.parse(JSON.stringify(localStorage.getItem("storeGlobalAttribute")))
        );

        const SSOSalesRepId = globalAttributeVal?.filter((x: { AttributeCode: string }) => {
          return x.AttributeCode == "SSOSalesRepId";
        })[0]?.AttributeValue;
        response.User.ExternalId = SSOSalesRepId;
      }

      // setting the Znode userId in local storage and axios headers
      setAxiosHeader(response?.User?.UserId);
      persistUserId(response?.User?.UserId);
      // setting the user details in local storage
      const userDetails = {
        UserName: response?.User?.UserName,
        FirstName: response?.User?.FirstName,
        LastName: response?.User?.LastName,
        ExternalId: response?.User?.ExternalId,
        EmailId: response?.User?.Email,
      };
      persistUserDetails(userDetails);
      return response;
    } catch (error: any) {
      if (error?.response?.data?.ErrorCode === 1004) {
        return Promise.reject(perlickConstant.AccountDisabled);
      } else if (error?.response?.data?.ErrorCode === 1003) {
        return Promise.reject(perlickConstant.InvalidUsernamePassword);
      } else if (error?.response?.data?.ErrorCode === 9001) {
        return Promise.reject(perlickConstant.AccountLoginFailed);
      } else if (error?.response?.data?.ErrorCode === 1008) {
        return Promise.reject(perlickConstant.TwoAttemptsToLogIn);
      } else if (error?.response?.data?.ErrorCode === 1009) {
        return Promise.reject(perlickConstant.OneAttemptToLogIn);
      } else {
        return Promise.reject(error);
      }
    }
  }
);

export const doSetClient = createAsyncThunk(
  "auth/doSetClient",
  async (clientPayload: RefreshClient) =>
    setClientToken(clientPayload).then((res) => {
      return res;
    })
);

export const doSignUp = createAsyncThunk("auth/doSignUp", async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload)
);

export const doResetPassword = createAsyncThunk(
  "auth/doResetPassword",
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload)
);

export const doVerifySecurityCode = createAsyncThunk(
  "auth/doVerifySecurityCode",
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload)
);

export const doSetNewPassword = createAsyncThunk(
  "auth/doSetNewPassword",
  async (newPasswordData: NewPasswordData) => setNewPassword(newPasswordData)
);

export const doLogout = createAsyncThunk("auth/doLogout", () => {
  deleteToken();
  deleteUser();
  removeAllCookies();
  clearLocalStorage();
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getUserData: (state, action: PayloadAction<{ salesRepId: string; email: string }>) => {
      state.salesRepId = action.payload.salesRepId ? action.payload.salesRepId : "";
      state.email = action.payload.email;
    },
    updateUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    updateSSOLoader: (state, action: PayloadAction<boolean>) => {
      state.ssoLoader = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.user = action.payload;
      state.salesRepId = action.payload.User.ExternalId;
      state.loading = false;
    });
    builder.addCase(doLogin.rejected, (state) => {
      state.error = LOGIN_ERROR_MESSAGE;
      state.user = null;
      state.loading = true;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.user = null;
      state.loading = false;
    });
  },
});

export const { getUserData, updateUserEmail, updateSSOLoader } = authSlice.actions;

export default authSlice.reducer;
