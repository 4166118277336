import React from "react";
import { Spin } from "antd";

interface LoaderProps {
  loading: boolean;
}
export const Loader: React.FC<LoaderProps> = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="center-loader" data-test-selector="loader">
          <Spin />
        </div>
      )}
    </>
  );
};

export default Loader;
