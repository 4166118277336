import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { perlickConstant } from "config/Perlick.Constant";
import {
  IRMAListRequest,
  IRMAListResponse,
} from "interfaces/APIInterface/IPerlickAPIInterface/IRMA";

export interface IRMAData {
  rmaTotalPage: number;
  rmaActivePageNumber: number;
  rmaListPayload: IRMAListRequest;
  rmaListData: IRMAListResponse[];
  rmaListTime: string;
}

const initialState: IRMAData = {
  rmaTotalPage: 0,
  rmaActivePageNumber: perlickConstant.DefaultPageIndex,
  rmaListPayload: {
    SalesRepID: "",
    CustID: "",
    CustomerName: "",
    ReturnNum: 0,
    ReturnLineNum: "",
    ReturnDateFrom: "",
    ReturnDateTo: "",
    SKU: "",
    RevisionsNum: "",
    OrderedQTYFrom: 0,
    OrderedQTYTo: 0,
    ReturnedQTYFrom: 0,
    ReturnedQTYTo: 0,
    Status: "",
    OrderNum: "",
    OrderLine: 0,
    SerialNum: "",
    filtersList: [],
    Refresh: false,
    CustomRangeReturnDateFrom : "",
    CustomRangeReturnDateTo : ""
  },
  rmaListData: [],
  rmaListTime: "",
};

export const rmaSlice = createSlice({
  name: "rma",
  initialState,
  reducers: {
    getRMAListData: (state, action: PayloadAction<IRMAListResponse[]>) => {
      state.rmaListData = action.payload;
    },
    getRMAtRefreshTime: (state, action: PayloadAction<string>) => {
      state.rmaListTime = action.payload;
    },
    getRmaTotalPage: (state, action: PayloadAction<number>) => {
      state.rmaTotalPage = action.payload;
    },
    getRmaPageNumber: (state, action: PayloadAction<number>) => {
      state.rmaActivePageNumber = action.payload;
    },
  },
});

export const { getRMAListData, getRMAtRefreshTime, getRmaTotalPage, getRmaPageNumber } =
  rmaSlice.actions;

export default rmaSlice.reducer;
