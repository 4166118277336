import React, { useContext, useEffect, useState } from "react";
import { AppRouter } from "routes/AppRouter";
import { MsalProvider } from "@azure/msal-react";
import "./assets/styles/App.scss";
import { pca } from "../src/authConfig";
//import Cookies from "js-cookie";
//import { getAPIToken } from "api/http.api";
import { readUserId } from "api/services/localStorage.service";
import Loader from "components/Common/Loader/Loader";
import { getPermissionsData } from "api/services/permission.api";
import { AbilityContext } from "ability/Can";
import { ISecurityPermission } from "interfaces/ClientInterface/IAbility";
import { PERMISSION_CODE, PERMISSION_SUBJECT } from "ability/utils";
import { isObjectEmpty } from "utils";
import { notificationController } from "components/Common/Notification/Notification";
import { translate } from "i18n";

const MainContent = () => {
  return <AppRouter />;
};

function App() {
  const [loader, setLoader] = useState(false);
  const ability = useContext(AbilityContext);

  useEffect(() => {
    if (readUserId()) {
      setLoader(true);
      readUserId() &&
        getPermissionsData(readUserId(), ability)
          .then((res) => {
            setLoader(false);
            //logout if the login permission is false
            const loginPermission = res?.RoleAndPermissionList?.find(
              (item: ISecurityPermission) => {
                return (
                  item?.Object == PERMISSION_SUBJECT.SECURITY &&
                  item?.PermissionCode == PERMISSION_CODE.ACCESS_ORDER_MANAGEMENT
                );
              }
            );
            const globalAttributeVal = JSON.parse(
              JSON.parse(JSON.stringify(localStorage.getItem("storeGlobalAttribute")))
            );

            const SSOSalesRepId = globalAttributeVal?.filter((x: { AttributeCode: string }) => {
              return x.AttributeCode == "SSOSalesRepId";
            })[0]?.AttributeValue;

            const ExternalId = localStorage.getItem("ExternalId");

            if (SSOSalesRepId != ExternalId) {
              if (isObjectEmpty(loginPermission)) {
                notificationController.error({
                  message: translate("login.invalidLoginPermission"),
                  placement: "top",
                });
                window.location.href = "/logout";
              }
            }
          })
          .catch(() => {
            setLoader(false);
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readUserId()]);

  if (loader) {
    return <Loader loading={loader} />;
  }

  return (
    <MsalProvider instance={pca}>
      <MainContent />
    </MsalProvider>
  );
}

export default App;
