import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "routes/Routes.Constant";
import { translate } from "i18n";

const NotAuthorisedPage = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="403"
      title=""
      subTitle={translate("common.noAccessPermissionMessage")}
      extra={
        <Button type="primary" onClick={() => navigate(`/${ROUTES_PATH.ORDERS}`)}>
          Back Home
        </Button>
      }
    />
  );
};

export default NotAuthorisedPage;
