/* eslint-disable max-len */


import React from "react";
import { Row, Col, Card} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const RedirectApiErrorMessage = () => {
  const APIErrorMessage = localStorage.getItem("ContentErrorMessage") ?? "";
  return (
    <>
      <Card className="customer-service-card mt-8">
        <Row className="justify-center mt-24">
          <Col span={24} className="text-center" style={{ fontSize: "52px" }}>
            <ExclamationCircleOutlined width={60} preload="false"/>
          </Col>
          <Col span={24} className="text-center m-0">
            <div  dangerouslySetInnerHTML={{__html: APIErrorMessage}} style={{fontSize: "1rem", marginTop: "1rem"}}></div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
