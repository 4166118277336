import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { checkEditOrderPermission } from "components/EditOrder/EditOrder.Constant";
import { IEditOrderPermissions } from "interfaces/ClientInterface/IEditOrder";

interface IPer {
  permissionsList: IEditOrderPermissions;
  isEditPermission: boolean;
}

const initialState: IPer = {
  permissionsList: {
    IsDelete: false,
    IsEdit: false,
    IsView: false,
    allowEditing: false,
    IsDelegatedToMe: false,
  },
  isEditPermission: false,
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    getPermissions: (state, action: PayloadAction<IEditOrderPermissions>) => {
      const data = { ...state.permissionsList, ...action.payload };
      state.permissionsList = data;
      state.isEditPermission = checkEditOrderPermission(data);
    },
  },
});

export const { getPermissions } = permissionSlice.actions;
export default permissionSlice.reducer;
