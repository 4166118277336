import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { perlickConstant } from "config/Perlick.Constant";
import { IHierarchyResponse } from "interfaces/ClientInterface/PerlickClientInterface/IHierarchy";
import { ISalesRepListResponse } from "interfaces/ClientInterface/PerlickClientInterface/ISalesRep";

export interface ISalesData {
  salesListData: ISalesRepListResponse[];
  salesTotalPage: number;
  salesActivePageNumber: number;
  hierarchyData: IHierarchyResponse[];
  parentData: IHierarchyResponse[];
  parentNumberOfRecords: number;
}

const initialState: ISalesData = {
  salesListData: [],
  hierarchyData: [],
  salesTotalPage: 0,
  salesActivePageNumber: perlickConstant.DefaultPageIndex,
  parentData: [],
  parentNumberOfRecords: 0,
};

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    getSalesListData: (state, action: PayloadAction<ISalesRepListResponse[]>) => {
      state.salesListData = action.payload;
    },
    getHierarchyData: (state, action: PayloadAction<IHierarchyResponse[]>) => {
      state.hierarchyData = action.payload;
    },
    getHierarchyParentData: (state, action: PayloadAction<IHierarchyResponse[]>) => {
      state.parentData = action.payload;
    },
    getHierarchyParentNumberOfRecords: (state, action: PayloadAction<number>) => {
      state.parentNumberOfRecords = action.payload;
    },
    getSalesTotalPage: (state, action: PayloadAction<number>) => {
      state.salesTotalPage = action.payload;
    },
    getSalesPageNumber: (state, action: PayloadAction<number>) => {
      state.salesActivePageNumber = action.payload;
    },
  },
});

export const {
  getSalesListData,
  getHierarchyData,
  getSalesTotalPage,
  getSalesPageNumber,
  getHierarchyParentData,
  getHierarchyParentNumberOfRecords,
} = salesSlice.actions;

export default salesSlice.reducer;
