import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// import { IWarrantyRequest } from "interfaces/ClientInterface/PerlickClientInterface/IWarranty";

export interface IWarranty {
    SerialNumber: string;
}

const initialState: IWarranty = {
  SerialNumber: "",
};

export const warrantySlice = createSlice({
  name: "warranty",
  initialState,
  reducers: {
    getSerialNumber: (state, action: PayloadAction<string>) => {
      state.SerialNumber = action.payload;
    },
  },
});

export const { getSerialNumber } = warrantySlice.actions;

export default warrantySlice.reducer;
