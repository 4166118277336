import React from "react";
import { Card, Row, Col } from "antd";
import "../../PerlickComponents/Dashboard/PerlickDashboard.scss";
import { translate } from "i18n";

export default function Alerts() {
  return (
    <Card
      title="Alerts"
      extra={
        <a href="#" data-test-selector="viewAllAlerts">
          {translate("common.linkViewAll")}
        </a>
      }
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={24}>
          <div className="list-elements">
            <div className="date-label">
              <label>May 14, 2023</label>
            </div>
            <div className="alerts-elements">
              <a href="#" data-test-selectors="alertsElements">
                Lorem Ipsum is simply dummy text of the printing
              </a>
            </div>
          </div>
          <div className="list-elements">
            <div className="date-label">
              <label>May 14, 2023</label>
            </div>
            <div className="alerts-elements">
              <a href="#" data-test-selectors="alertsElements">
                Lorem Ipsum is simply dummy text of the printing
              </a>
            </div>
          </div>
          <div className="list-elements">
            <div className="date-label">
              <label>May 14, 2023</label>
            </div>
            <div className="alerts-elements">
              <a href="#" data-test-selectors="alertsElements">
                Lorem Ipsum is simply dummy text of the printing
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
