import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { perlickConstant } from "config/Perlick.Constant";
import {
  IServiceListRequest,
  IServiceListResponse,
} from "interfaces/ClientInterface/PerlickClientInterface/IServiceList";

export interface IServiceData {
  serviceTotalPage: number;
  serviceActivePageNumber: number;
  serviceListPayload: IServiceListRequest;
  serviceListData: IServiceListResponse[];
  serviceCallListTime: string;
}

const initialState: IServiceData = {
  serviceTotalPage: 0,
  serviceActivePageNumber: perlickConstant.DefaultPageIndex,
  serviceListPayload: {
    SalesRepId: "",
    CustID: "",
    CustomerName: "",
    CallNumber: "",
    CallType: "",
    RequestedDateFrom: "",
    RequestedDateTo: "",
    ScheduledDateFrom: "",
    ScheduledDateTo: "",
    ActualDateFrom: "",
    ActualDateTo: "",
    CallDurationFrom: "",
    CallDurationTo: "",
    PageNo: perlickConstant.DefaultPageIndex,
    Rows: perlickConstant.DefaultNumberOfRows,
    filtersList: [],
    Refresh: false,
  },
  serviceListData: [],
  serviceCallListTime: "",
};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    getServiceList: (state, action: PayloadAction<IServiceListResponse[]>) => {
      state.serviceListData = action.payload;
    },
    getServiceCallRefreshTime: (state, action: PayloadAction<string>) => {
      state.serviceCallListTime = action.payload;
    },
    getServiceTotalPage: (state, action: PayloadAction<number>) => {
      state.serviceTotalPage = action.payload;
    },
    getServicePageNumber: (state, action: PayloadAction<number>) => {
      state.serviceActivePageNumber = action.payload;
    },
  },
});

export const {
  getServiceList,
  getServiceCallRefreshTime,
  getServiceTotalPage,
  getServicePageNumber,
} = serviceSlice.actions;

export default serviceSlice.reducer;
