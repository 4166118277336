import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IPackingSlipDetails {
  PackNum: string;
}

const initialState: IPackingSlipDetails = {
  PackNum: "",
};

export const packingSlipSlice = createSlice({
  name: "packingSlip",
  initialState,
  reducers: {
    getPackingSlipId: (state, action: PayloadAction<string>) => {
      state.PackNum = action.payload;
    },
  },
});

export const { getPackingSlipId } = packingSlipSlice.actions;

export default packingSlipSlice.reducer;
