import { PureAbility } from "@casl/ability";
import updateAbility from "ability/updateAbility";
import { httpApi } from "api/http.api";
import { ISecurityPermission } from "interfaces/ClientInterface/IAbility";

export const getPermissionsData = (userId: string, ability: PureAbility<[string, string]>) => {
  return httpApi
    .post("/perlickcustomer/getroleandpermissionsonfrontend", { userId: userId })
    .then((response) => {
      const permissionResponse: ISecurityPermission[] = response?.data?.RoleAndPermissionList;
      // setting the roles and permission data into the ability
      updateAbility(ability, permissionResponse);
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getEditOrderPermissionsData = (OrderId: string | undefined, OrderStateId = 0) => {
  return httpApi
    .get("/commerceportaluser/getItemLevelPermissions/" + OrderId + "/" + OrderStateId)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};
