import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { perlickConstant } from "config/Perlick.Constant";
import { IShipmentListRequest } from "interfaces/APIInterface/IPerlickAPIInterface/IShipment";
import { IShipmentListResponse } from "interfaces/ClientInterface/PerlickClientInterface/IShipment";

export interface IShipmentDetails {
  ShipmentActivePageNumber: number;
  shipmentResponseList: IShipmentListResponse[];
  shipmentList: IShipmentListRequest;
  shipmentListTime: string;
  shipmentTotalPage: number;
}

const initialState: IShipmentDetails = {
  ShipmentActivePageNumber: perlickConstant.DefaultPageIndex,
  shipmentResponseList: [],
  shipmentList: {
    SalesRepId: "",
    CustomerID: "",
    CustomerName: "",
    ShipmentDateFrom: "",
    ShipmentDateTo: "",
    PackingSlipNumber: "",
    PONumber: "",
    JobName: "",
    DeliveryMethod: "",
    TrackingNumber: "",
    TrackingCarrier: "",
    PageNo: 1,
    Rows: 10,
    filtersList: [],
    Refresh: false,
    ShipDateFrom:"",
    ShipDateTo:""
  },
  shipmentListTime: "",
  shipmentTotalPage: 0,
};

export const shipmentSlice = createSlice({
  name: "shipment",
  initialState,
  reducers: {
    getShipmentResponseList: (state, action: PayloadAction<IShipmentListResponse[]>) => {
      state.shipmentResponseList = action.payload;
    },
    getShipmentRefreshTime: (state, action: PayloadAction<string>) => {
      state.shipmentListTime = action.payload;
    },
    getShipmentTotalPage: (state, action: PayloadAction<number>) => {
      state.shipmentTotalPage = action.payload;
    },
    getShipmentPageNumber: (state, action: PayloadAction<number>) => {
      state.ShipmentActivePageNumber = action.payload;
    },
  },
});

export const {
  getShipmentResponseList,
  getShipmentRefreshTime,
  getShipmentTotalPage,
  getShipmentPageNumber,
} = shipmentSlice.actions;

export default shipmentSlice.reducer;
