import authReducer from "store/slices/auth.slice";
import useReducer from "store/slices/user.slice";
import orderReducer from "store/slices/order.slice";
import invoiceReducer from "store/slices/invoice.slice";
import shipmentReducer from "store/slices/shipment.slice";
import packingSlipReducer from "store/slices/packingSlip.slice";
import calculatorReducer from "store/slices/calculator.slice";
import permissionReducer from "store/slices/permission.slice";
import editOrderReducer from "store/slices/editOrder.slice";
import warrantyReducer from "store/slices/warranty.slice";
import freshdeskReducer from "store/slices/freshdesk.slice";
import rmaReducer from "store/slices/rma.slice";
import serviceReducer from "store/slices/service.slice";
import salesReducer from "store/slices/sales.slice";
import customerReducer from "store/slices/customer.slice";
import paymentReducer from "store/slices/payment.slice";

const reducer = {
  auth: authReducer,
  user: useReducer,
  order: orderReducer,
  invoice: invoiceReducer,
  shipment: shipmentReducer,
  packingSlip: packingSlipReducer,
  calculator: calculatorReducer,
  permission: permissionReducer,
  editOrder: editOrderReducer,
  warranty: warrantyReducer,
  freshdesk: freshdeskReducer,
  rma: rmaReducer,
  service: serviceReducer,
  sales: salesReducer,
  customer: customerReducer,
  payment: paymentReducer,
};

export default reducer;
