import axios from "axios";

export const PerlickAnalyticsId = (portalId: number) => {
  return axios
    .get(`${process.env.REACT_APP_THUMBNAIL_IMAGE_PATH}/tagmanager/get/${portalId}`)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};
