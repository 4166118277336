import { createSlice } from "@reduxjs/toolkit";
import {
  ICalculator1,
  ICalculator2,
} from "interfaces/ClientInterface/PerlickClientInterface/ICalculator";

export interface ICalculator {
  calculator_1: ICalculator1;
  calculator_2: ICalculator2;
}

const initialState: ICalculator = {
  calculator_1: {
    InputText10: "",
    DropDownT0: 1,
    InputText11: "",
    DropDownT1: 1,
    InputText12: "",
    DropDownT2: 1,
    InputText13: "",
    DropDownT3: 1,
    InputText14: "",
    DropDownT4: 1,
    InputTextF0: "",
    InputTextF1: "",
    InputTextF2: "",
    InputTextF3: "",
    PptoCooler: "",
    PpAmbient: 1,
    Voltage: 0,
    TubeType: 0,
  },
  calculator_2: {
    FlashChill: 0,
    InputText10: "",
    DropDownT0: 1,
    InputText11: "",
    DropDownT1: 1,
    InputText12: "",
    DropDownT2: 1,
    InputText13: "",
    DropDownT3: 1,
    InputText14: "",
    DropDownT4: 1,
    InputTextF0: "",
    InputTextF1: "",
    InputTextF2: "",
    InputTextF3: "",
    PptoCooler: "",
    Rdtoglycolbath: "",
    PpAmbient: 1,
  },
};

export const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {},
});

export default calculatorSlice.reducer;
