import axios, { AxiosError } from "axios";
import {
  clearLocalStorage,
  deleteToken,
  deleteUser,
  readUserId,
  removeAllCookies,
} from "./services/localStorage.service";
import { ApiError } from "./ApiError";
import { API_GATEWAY_URL, RefreshTokenSession } from "config/constant";
import Cookies from "js-cookie";
import { notificationController } from "components/Common/Notification/Notification";

let authToken = "";

export const getAPIToken = (token: string) => {
  authToken = token;
};

export interface ApiResponse<T> {
  data: T;
}

export interface ApiErrorData {
  message: string;
  ErrorMessage: string;
}

// Set-Authorization
export const httpApi = axios.create({
  baseURL: API_GATEWAY_URL,
});

export function setupInterceptors() {
  //setting request header
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  httpApi.interceptors.request.use((config: any) => {
    const localToken = Cookies.get("DataT");
    const parstToken = localToken !== null ? localToken : "";
    if (authToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${authToken}`,
        "Znode-UserId": readUserId(),
      };
      return config;
    } else {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${parstToken}`,
        "Znode-UserId": readUserId(),
      };
      return config;
    }
  });
  //checking response status
  httpApi.interceptors.response.use(
    (response) => {
      const newToken = response.headers["set-authorization"];
      newToken ? getAPIToken(newToken.toString()) : null;
      if (newToken) {
        const expirationInMinutes = RefreshTokenSession;
        const expirationTime = new Date(new Date().getTime() + expirationInMinutes * 60000);
        Cookies.set("DataT", newToken, { expires: expirationTime });
      }

      return response;
    },
    (error: AxiosError<ApiErrorData>) => {
      if (error?.response?.status === 500) {
        throw new ApiError<ApiErrorData>(
          error && error.response?.data?.ErrorMessage
            ? error.response?.data?.ErrorMessage
            : error.message
        );
      }

      if (error?.response?.status === 401) {
        notificationController.error({
          message: "Session Expired",
        });
        setTimeout(() => {
          deleteToken();
          deleteUser();
          removeAllCookies();
          clearLocalStorage();
          window.location.href = "/auth/login";
        }, 1000);
        throw new ApiError<ApiErrorData>(
          error && error.response?.data?.message ? error.response?.data?.message : error.message
        );
      }
    }
  );
}
