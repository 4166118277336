import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInvoiceState } from "./../../interfaces/ClientInterface/PerlickClientInterface/IInvoice";
import { perlickConstant } from "config/Perlick.Constant";
import { IInvoiceListResponse } from "interfaces/ClientInterface/PerlickClientInterface/IInvoice";

const initialState: IInvoiceState = {
  invoiceActivePageNumber: perlickConstant.DefaultPageIndex,
  filterCache: false,
  invoiceNo: 0,
  invoiceListMapData: [],
  invoiceList: {
    Rows: perlickConstant.DefaultNumberOfRows,
    PageNo: perlickConstant.DefaultPageIndex,
    SalesRepID: "",
    CustID: "",
    CustomerName: "",
    InvoiceNo: 0,
    FromInvoiceDate: "",
    ToInvoiceDate: "",
    FromDueDate: "",
    ToDueDate: "",
    OrderNo: 0,
    PONo: "",
    InvoiceStatus: "",
    JobName: "",
    OrderAmountFrom: "",
    OrderAmountTo: "",
    BalanceDueFrom: "",
    BalanceDueTo: "",
    DueDateFrom: "",
    DueDateTo: "",
    AmountFrom: 0,
    AmountTo: 0,
    CurrentUserRepCode: "",
    filtersList: [],
    Refresh: false,
    InvoiceDateFrom:"",
    InvoiceDateTo:"",
  },
  invoiceListTime: "",
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    getInvoiceNo: (state, action: PayloadAction<number>) => {
      state.invoiceNo = action.payload;
    },
    getInvoiceListTotalData: (state, action: PayloadAction<IInvoiceListResponse[]>) => {
      state.invoiceListMapData = action.payload;
    },
    getFilterCache: (state, action: PayloadAction<boolean>) => {
      state.filterCache = action.payload;
    },
    getInvoiceRefreshTime: (state, action: PayloadAction<string>) => {
      state.invoiceListTime = action.payload;
    },
    getInvoicePageNumber: (state, action: PayloadAction<number>) => {
      state.invoiceActivePageNumber = action.payload;
    },
  },
});

export const {
  getInvoiceNo,
  getInvoiceListTotalData,
  getFilterCache,
  getInvoiceRefreshTime,
  getInvoicePageNumber,
} = invoiceSlice.actions;
export default invoiceSlice.reducer;
