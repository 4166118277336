import React, { useState } from "react";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { Footer } from "../../components/Common/Footer/Footer";
import "./MainLayout.scss";
import { Outlet } from "react-router-dom";
import HeaderComponent from "components/Common/PerlickCommon/Header/Header";
import Sider from "antd/es/layout/Sider";
import SidebarMenu from "./SidebarNavigation";

const MainLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  return (
    <>
      <HeaderComponent collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout className="sider-layout-style">
        <Sider trigger={null} collapsible collapsed={collapsed} className="sider-style">
          <SidebarMenu />
        </Sider>
        <Layout className="site-layout">
          <Content className="content-container">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <Footer />
    </>
  );
};

export default MainLayout;
