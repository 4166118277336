import React from "react";
import { Image } from "antd";
import { ILogo } from "interfaces/ClientInterface/ILogo";

export default function Logo(props: ILogo) {
  const { imageWidth, imageHeight, isPreview, className, imageUrl } = props;
  return (
    <Image
      className={className}
      src={imageUrl}
      preview={isPreview}
      width={imageWidth}
      height={imageHeight}
    />
  );
}
