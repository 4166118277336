export const PERLICK_ROUTES_PATH = {
  DASHBOARD: "/dashboard",
  CALCULATOR_1: "/PowerPakCalculator",
  CALCULATOR_3: "/CenturySystemDesign",
  CALCULATOR_2: "/ArcticCalculator",
  CALCULATOR_4: "/DraughtGuardSavingsCalculator",
  CUSTOMER_DETAILS: "/customer-details",
  CUSTOMER: "/customers",
  CREATE_TICKET: "/create-ticket",
  TICKET: "/tickets",
  ORDERS: "/orders",
  INVOICE: "/invoices",
  ORDER_DETAILS: "/order-details",
  INVOICE_DETAIL: "/invoice-Details",
  PAYMENTS: "/payments",
  PACKING_SLIP: "/packing-slip",
  SERVICE_LIST: "/service-list",
  SALES_REP_LIST: "/sales-list",
  RMA: "/rma",
  HIERARCHY: "/hierarchy",
  SALES_CUSTOMERS: "/sales-customers",
  SERVICE: "/services",
  SERVICE_PART: "/service-part",
  SHIPMENT: "/shipment",
  WARRANTY: "/warranty",
  CHANGE_PASSWORD: "/user/change-password",
  CHANGE_PROFILE: "/user/user-profile",
  FRESHDESK_DETAILS: "/freshdesk-Details",
  USER_REGISTRATION: "/user/registration",
  COMMERCIAL_PRODUCTS: "https://www.perlick.com/products/commercial-products.html",
  RESIDENTIAL_PRODUCTS: "https://www.perlick.com/products/residential-products.html",
  PARTS: "https://www.partstown.com/perlick/parts",
  Perlick_News: "/perlick-news",
};
