import React from "react";
import { IProductDetails } from "interfaces/APIInterface/IQuickViewOrder";
import { translate } from "i18n";
import { getGlobalSetting} from "api/services/localStorage.service";

export const columns = (ProductsList: IProductDetails[]) => {
  const record = ProductsList?.[0];
  const globalSettings = getGlobalSetting() ? JSON.parse(getGlobalSetting() || "") : {};
  const priceRoundOff = globalSettings?.PriceRoundOff;
  const columnsData = [
    {
      title: <div>{`${translate("common.titleProductName")} (${record?.totalProductsCount})`}</div>,
      dataIndex: "ProductName",
      key: "ProductName",
      width: "315px",
    },
    {
      title: translate("common.titleSku"),
      dataIndex: "SKU",
      key: "Sku",
    },
    {
      title: <div>{`${translate("common.titleQuantity")} (${record?.totalProductQuantity})`}</div>,
      dataIndex: "Quantity",
      key: "Quantity",
    },
    {
      title: translate("common.titlePrice"),
      dataIndex: "Price",
      key: "Price",
    },
    {
      title: <div>{`${translate("common.titleTotal")} 
      ($${(record?.totalProductPrice || 0.00).toFixed(priceRoundOff)})`}</div>,
      dataIndex: "Total",
      key: "Total",
    },
  ];
  return ProductsList && ProductsList?.length ? columnsData : [];
};
