import React from "react";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";

import { ExclamationCircleFilled, InfoCircleFilled } from "@ant-design/icons";

type NotificationProps = {
  message: string | React.ReactNode;
  description?: string;
  placement?: NotificationPlacement;
  duration?: number;
  className?: string;
};

const openSuccessNotification = (config: NotificationProps): void => {
  notification.destroy();
  notification.success({
    ...config,
    icon: <span> </span>,
    message: <div className={`title ${!config.description && "title-only"}`}>{config.message}</div>,
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <div />
    ),
    className: config.className
      ? config.className
      : "notification-without-description success-notification",
    placement: config.placement ? config.placement : "top",
    duration: config.duration ? config.duration : 3,
  });
};

const openInfoNotification = (config: NotificationProps): void => {
  notification.destroy();
  notification.info({
    ...config,
    icon: (
      <div>
        <InfoCircleFilled className="info-icon" />
      </div>
    ),
    message: <div className={`title ${!config.description && "title-only"}`}>{config.message}</div>,
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <div />
    ),
    className: config.className
      ? config.className
      : "notification-without-description info-notification",
    placement: config.placement ? config.placement : "top",
    duration: config.duration ? config.duration : 3,
  });
};

const openWarningNotification = (config: NotificationProps): void => {
  notification.destroy();
  notification.warning({
    ...config,
    icon: (
      <div>
        <ExclamationCircleFilled className="warning-icon" />
      </div>
    ),
    message: <div className={`title ${!config.description && "title-only"}`}>{config.message}</div>,
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <div />
    ),
    className: config.className
      ? config.className
      : "notification-without-description warning-notification",
    placement: config.placement ? config.placement : "top",
    duration: config.duration ? config.duration : 3,
  });
};

const openErrorNotification = (config: NotificationProps): void => {
  notification.destroy();
  notification.error({
    ...config,
    icon: <span></span>,
    message: <div className={`title ${!config.description && "title-only"}`}>{config.message}</div>,
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <div />
    ),
    className: config.className
      ? config.className
      : "notification-without-description error-notification",
    placement: config.placement ? config.placement : "top",
    duration: config.duration || config.duration == 0 ? config.duration : 3,
  });
};

export const notificationController = {
  success: openSuccessNotification,
  info: openInfoNotification,
  warning: openWarningNotification,
  error: openErrorNotification,
};
